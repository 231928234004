<template>
    <el-card class="box-card">
        <div slot="header" class="clearfix" style="margin-bottom: 20px">
            <h3 class="inBlock">轮播图列表</h3>
        </div>
        <div slot="header" class="clearfix">
            <el-button type="primary" plain size="small" @click="addImgurl"> +添加轮播图 </el-button>
        </div>
        <el-table :data="tableData" border style="width: 100%">
            <el-table-column label="ID" prop="id"> </el-table-column>
            <el-table-column label="排序" prop="sort" sortable> </el-table-column>
            <el-table-column label="封面图片" prop="imgurl">
                <template slot-scope="scope">
                    <img style="width: 50px" :src="imgurl + scope.row.imgurl" alt="" />
                </template>
            </el-table-column>
            <el-table-column label="状态">
                <div slot-scope="scope">
                    <span
                        class="status"
                        style="background-color: #1aad19"
                        v-if="scope.row.status == 1"
                        >显示</span
                    >
                    <span
                        class="status"
                        style="background-color: #626365"
                        v-if="scope.row.status == 5"
                        >隐藏</span
                    >
                    <strong
                        class="setstatus shou"
                        @click="setStatus(scope.row.id)"
                        >修改</strong
                    >
                </div>
            </el-table-column>
            <el-table-column label="操作">
                <div slot-scope="scope">
                    <el-button
                        size="mini"
                        round
                        type="primary"
                        @click="bianji(scope.row)"
                        >编辑</el-button
                    >
                    <el-button
                        size="mini"
                        round
                        type="danger"
                        @click="deleteBanner(scope.row.id)"
                        >删除</el-button
                    >
                </div>
            </el-table-column>
        </el-table>
        <div class="block">
            <el-pagination
                @current-change="handleCurrentChange"
                layout="prev, pager, next"
                :total="total"
            >
            </el-pagination>
        </div>

        <!-- 添加编辑轮播图 -->
        <el-dialog title="轮播图" width="30%" :visible.sync="dialogNewsBanner">
            <el-form :rules="rules" :model="formdata" ref="addNewsBanner">
                <el-form-item label="轮播图" style="margin-top: 70px">
                    <div class="imgbox">
                        <div
                            v-for="(item, index) in imagesary"
                            :key="index"
                            class="imgItem"
                            >
                            <span class="shou" @click="delImg(item)">×</span>
                            <img class="avatar" :src="imgurl + item" alt="" />
                        </div>
                        <up-img @addimg="upDataimg" :type="'goods'"></up-img>
                    </div>
                </el-form-item>
                <el-form-item label="链接地址:" prop="linkurl">
                    <el-input
                        v-model="formdata.linkurl"
                        placeholder="请输入链接地址"
                    ></el-input>
                </el-form-item>
                <el-form-item prop="weigh" style="margin-left: 15px" label="排序:">
                    <el-input
                        v-model="formdata.sort"
                        placeholder="请输入排序"
                    ></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogNewsBanner = false">取 消</el-button>
                <el-button type="primary" @click="addNewsBanner">确 定</el-button>
            </div>
        </el-dialog>

    </el-card>
</template>
<script>
import {
  getNewsBannerList,
  setNewsBannerStatus,
  addNewsBanner,
  delNewsBanner,
  getNewsBannerDetails
} from "@/api/api.js";
import { getSystemsettings } from "../../api/getSystemsetting.js";
import UpImg from "@/components/upImg.vue";
export default {
    components: { UpImg },
    data(){
        return{
            tableData:[],
            imgurl:'',
            total: 0,
            page:{
                page:1
            },
            dialogNewsBanner:false,
            formdata:{
                imgurl:'',    // 图片地址
                linkurl:'',    // 链接地址
                type:'',    // 关联类型：1.关联资讯，2.关联商品
                relation_id:'',   // 关联id（根据关联类型去查相对应的表）
                sort:'',      // 排序
                id:''
            },
            imagesary: [],
            rules: {
                // linkurl: [{ required: true, message: "请输入跳转链接", trigger: "blur" }]
            },
        }
    },
    methods:{
        async getlist() {
            const { data } = await getNewsBannerList(this.page);
            console.log(data);
            if (data.code != 200) return this.$message.error(data.data);
            this.tableData = data.data.goods_list.data;
            this.total = data.data.goods_list.total;
        },

        handleCurrentChange(val) {
            this.page.page = val;
            this.getlist();
        },

        async setStatus(id) {
            const { data } = await setNewsBannerStatus({ id: id});
            if (data.code != 200) return this.$message.error(data.data);
            this.$message.success("修改状态成功!");
            this.getlist();
        },

        delImg(item) {
            let index = this.imagesary.indexOf(item);
            this.imagesary.splice(index, 1);
        },
        upDataimg(e) {
            if (this.imagesary.length >= 1) {
                this.imagesary.shift();
            }
            this.imagesary.push(e);
        },

        addImgurl(){
            this.dialogNewsBanner = true
            this.imagesary = []
            this.formdata.linkurl = '';
            this.formdata.sort = '';
        },

        // 添加轮播图
        addNewsBanner() {
            this.imagesary.forEach((item) => {
                this.formdata.imgurl = item.toString();
            });
            this.$refs["addNewsBanner"].validate(async (valid) => {
                if (valid) {
                    const { data } = await addNewsBanner(this.formdata);
                    if (data.code != 200) return this.$message.error(data.data);
                    this.$message.success(data.data);
                    this.dialogNewsBanner = false;
                    this.getlist();
                } else {
                    this.$message.warning("请检查填写内容!");
                    return false;
                }
            });
        },


        // 删除轮播图
        deleteBanner(id) {
            this.$confirm("真的要删除该轮播图吗?, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
            .then(async () => {
                const { data } = await delNewsBanner({ id });
                if (data.code != 200) return this.$message.error(data.data);
                this.tableData.forEach((item) => {
                    if (item.id == id) {
                    let index = this.tableData.indexOf(item);
                    this.tableData.splice(index, 1);
                    }
                });
                this.$message({
                    type: "success",
                    message: "删除成功!",
                });
            })
            .catch(() => {
                this.$message({
                    type: "info",
                    message: "已取消删除",
                });
            });
        },

        async bianji(item) {
            this.formdata.id = item.id
            this.getNewsBannerDetails()
            this.dialogNewsBanner = true;
        },

        async getNewsBannerDetails(){
            const { data } = await getNewsBannerDetails({ id: this.formdata.id  });
            this.imagesary = data.data.imgurl.split(",")
            this.formdata.linkurl = data.data.linkurl;
            this.formdata.sort = data.data.sort;
        }

    },
    mounted() {
        getSystemsettings.then((res) => {
            this.imgurl = res;
        });
        this.getlist();
    },
}
</script>
<style scoped lang="less">
.status {
  padding: 2px 5px;
  color: #fff;
  border-radius: 3px;
  margin-right: 15px;
}
.setstatus {
  border-bottom: 1px solid #999;
  padding: 1px 2px;
  margin-left: 5px;
  font-size: 14px;
  &:hover {
    border-bottom: 1px solid #111;
    color: #111;
  }
}

.imgbox {
    display: flex;
    .imgItem {
        width: 178px;
        height: 178px;
        padding: 10px;
        margin-left: 10px;
        border-radius: 5px;
        position: relative;
        border: 1px solid #eee;
        span {
        position: absolute;
        background-color: #ffd1d1;
        top: 0;
        right: 0;
        height: 20px;
        text-align: center;
        line-height: 20px;
        width: 20px;
        z-index: 100;
        color: aliceblue;
            &:hover {
                background-color: #ff4544;
            }
        }
    }
    .avatar {
        width: 100%;
        height: 100%;
    }
}

</style>